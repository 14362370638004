import React from 'react';
import { Grid, useTheme, useMediaQuery, Typography } from '@mui/material';
import WebsiteCard from './Card';

const CardList = ({ websites }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!websites) {
        return (
            <Typography variant="body1" align="center" sx={{ mt: 4 }}>
                Loading...
            </Typography>
        );
    }

    if (websites.length === 0) {
        return (
            <Typography variant="body1" align="center" sx={{ mt: 4 }}>
                No stores found. Please try adjusting your filters.
            </Typography>
        );
    }

    return (
        <Grid 
            container 
            spacing={isMobile ? 2 : 3} 
            justifyContent="center"
            sx={{ 
                width: '100%', 
                margin: '0 auto',
                padding: isMobile ? '8px' : '16px'
            }}
        >
            {websites.map((website) => (
                <Grid 
                    item 
                    xs={12} 
                    sm={6} 
                    md={4} 
                    lg={3} 
                    key={website.id}
                >
                    <WebsiteCard
                        id={website.id}
                        name={website.name}
                        short_description={website.short_description}
                        categories={website.categories}
                        location={website.location}
                        stars={website.stars}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default CardList;
