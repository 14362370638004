// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomePage from './pages/HomePage';
import WebsiteDetailPage from './components/WebsiteDetailPage';
import Header from './components/Header';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/website/:websiteId" element={<WebsiteDetailPage />} />
        <Route path="/discover" element={<div>Discover</div>} />
        <Route path="/submit" element={<div>Submit</div>} />
      </Routes>
    </Router>
   /*  <div className="App">
      <ThemeProvider theme={theme}>
        <HomePage />
      </ThemeProvider>
    </div> */
  );
}

export default App;
