import React from 'react';
import TextField from '@mui/material/TextField';

const SearchBar = ({ value, onChange, sx }) => (
    <TextField
        label="Search Stores..."
        variant="outlined"
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{ 
            '& .MuiOutlinedInput-root': {
                height: '56px',
                backgroundColor: 'white',
                '& fieldset': {
                    borderColor: '#ddd',
                },
                '&:hover fieldset': {
                    borderColor: '#ddd',
                },
            },
            '& .MuiInputLabel-root': {
                transform: 'translate(14px, 16px) scale(1)',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                    transform: 'translate(14px, -9px) scale(0.75)',
                },
            },
            '& .MuiOutlinedInput-input': {
                padding: '0 14px',
            },
            ...sx
        }}
    />
);

export default SearchBar;
