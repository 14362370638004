import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const LocationFilter = ({ locations, selectedLocation, onLocationChange, sx }) => {
    const currentValue = selectedLocation || 'All Locations';
    
    return (
        <Autocomplete
            value={currentValue}
            onChange={(event, newValue) => {
                onLocationChange(newValue === 'All Locations' ? '' : newValue);
            }}
            options={['All Locations', ...locations].filter(Boolean)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Location"
                    placeholder="Select Location"
                    variant="outlined"
                />
            )}
            sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    height: '56px',
                    '& fieldset': {
                        borderColor: '#ddd',
                    },
                    '&:hover fieldset': {
                        borderColor: '#ddd',
                    },
                },
                '& .MuiInputLabel-root': {
                    transform: 'translate(14px, 16px) scale(1)',
                    '&.Mui-focused, &.MuiFormLabel-filled': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                },
                '& .MuiAutocomplete-input': {
                    padding: '0 14px !important',
                },
                ...sx
            }}
            disableClearable
            selectOnFocus
            handleHomeEndKeys
            isOptionEqualToValue={(option, value) => {
                if (value === '') return option === 'All Locations';
                return option === value;
            }}
        />
    );
};

export default LocationFilter;
