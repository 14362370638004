import React, { useState } from 'react';
import {
  Box,
  Typography,
  Rating,
  Button,
  Collapse
} from '@mui/material';

const InlineRating = ({ onSubmit, isOpen, onToggle }) => {
  const [ratings, setRatings] = useState({
    product_quality: 0,
    fresh_inventory: 0,
    fair_prices: 0,
    customer_service: 0
  });

  const criteria = [
    { key: 'product_quality', label: 'Product Quality' },
    { key: 'fresh_inventory', label: 'Fresh Inventory' },
    { key: 'fair_prices', label: 'Fair Prices' },
    { key: 'customer_service', label: 'Customer Service' }
  ];

  const handleSubmit = () => {
    onSubmit(ratings);
    onToggle();
    setRatings({
      product_quality: 0,
      fresh_inventory: 0,
      fair_prices: 0,
      customer_service: 0
    });
  };

  return (
    <Collapse in={isOpen}>
      <Box sx={{ 
        mt: 2, 
        p: 3, 
        border: '1px solid', 
        borderColor: 'divider',
        borderRadius: 1,
        bgcolor: 'background.paper'
      }}>
        <Typography variant="h6" gutterBottom>Rate this Store</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
          {criteria.map(({ key, label }) => (
            <Box key={key}>
              <Typography component="legend">{label}</Typography>
              <Rating
                name={key}
                value={ratings[key]}
                onChange={(event, newValue) => {
                  setRatings(prev => ({ ...prev, [key]: newValue }));
                }}
              />
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            disabled={Object.values(ratings).some(r => r === 0)}
          >
            Submit Rating
          </Button>
          <Button onClick={onToggle}>Cancel</Button>
        </Box>
      </Box>
    </Collapse>
  );
};

export default InlineRating;
