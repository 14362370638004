import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';

// Styled components for Search
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
  border: '1px solid #e0e0e0',
  '&:hover': {
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)',
  },
  marginLeft: theme.spacing(2),
  width: '100%',
  maxWidth: '250px', 
  transition: theme.transitions.create(['box-shadow', 'border-color']),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'black',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.75, 1, 0.75, 0), // Reduced vertical padding
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    '&:focus': {
      width: '100%',
      borderColor: theme.palette.primary.main,
    },
  },
}));

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      navigate(`/?search=${encodeURIComponent(searchValue)}`);
      setMenuOpen(false);
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'white',
        boxShadow: 3,
        borderBottom: '1px solid #e0e0e0',
        padding: '8px',
        mx: 'auto',
        mt: '16px',
        borderRadius: '8px',
        width: '90%',
        maxWidth: '1200px', // Adjust this value as needed
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Logo and Powered By Text */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'flex-end',
          gap: 2,
          flexShrink: 0 
        }}>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              textDecoration: 'none',
              color: 'black',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              lineHeight: 1,
              '&:hover': { color: 'primary.main' },
            }}
          >
            Vintage Stores
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="https://tryretrofit.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'none',
              color: 'text.secondary',
              fontSize: '0.75rem',
              lineHeight: 1,
              marginBottom: '0.25rem',
              '&:hover': { color: 'primary.main' },
              display: { sm: 'block' }
            }}
          >
            by Retrofit AI
          </Typography>
        </Box>

        {isMobile ? (
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            onClick={handleMenu}
            sx={{ 
              ml: 2,
              color: 'black',
            }}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexGrow: 1, justifyContent: 'flex-end' }}>
            {/* Submit Project Button */}
            <Button
              color="inherit"
              component="a"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdKzu03pOaNOoPIxoE_v4qKe363oj2gpTmIgcpr61Zz3y_Z2w/viewform?usp=sf_link"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textTransform: 'none',
                color: 'text.primary',
                '&:hover': { color: 'primary.main' },
                whiteSpace: 'nowrap',
                flexShrink: 0,
              }}
            >
              Submit a Store!
            </Button>

            {/* Search Bar */}
            {/* <Box sx={{ flexGrow: 0, width: '300px' }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search stores..."
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchValue}
                  onChange={handleSearch}
                  onKeyPress={handleSearchSubmit}
                />
              </Search>
            </Box> */}
          </Box>
        )}
      </Toolbar>
      {isMobile && (
        <Collapse in={menuOpen}>
          <Paper 
            elevation={0}
            square
            sx={{
              width: '100%',
              borderTop: '1px solid #e0e0e0',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Search sx={{ mb: 2, width: '90%', maxWidth: 'none' }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search stores..."
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchValue}
                  onChange={handleSearch}
                  onKeyPress={handleSearchSubmit}
                />
              </Search>
              <Button
                fullWidth
                component="a"
                href="https://your-chosen-url.com"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setMenuOpen(false)}
                sx={{
                  justifyContent: 'flex-start',
                  color: 'text.primary',
                  '&:hover': { color: 'primary.main' },
                  pl: `${theme.spacing(2)}`, // Align with the search text
                  py: 1, // Add some vertical padding
                }}
              >
                Submit a Store
              </Button>
            </Box>
          </Paper>
        </Collapse>
      )}
    </AppBar>
  );
};

export default Header;
