import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';

const WebsiteCard = ({ id, name, short_description, categories = [], location, stars }) => {
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const safeCategories = Array.isArray(categories) 
    ? categories.slice(0, 4)
    : [];

  const truncatedName = truncateText(name, 25);
  const truncatedDescription = truncateText(short_description, 50);

  const displayStars = stars !== null && stars !== undefined && !isNaN(stars) 
    ? Number(stars).toFixed(1) 
    : null;

  return (
    <Card
      component={Link}
      to={`/website/${id}`}
      sx={{
        maxWidth: 345,
        height: 200,
        width: '100%',
        margin: '16px auto',
        boxShadow: 3,
        textDecoration: 'none',
        borderRadius: 2,
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
        '&:hover': {
          boxShadow: 6,
          transform: 'scale(1.02)',
        },
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
      }}
    >
      <CardContent sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        padding: '16px', 
        '&:last-child': { paddingBottom: '16px' },
        height: '100%',
        gap: 1
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Tooltip title={name || 'Unnamed Store'} placement="top">
            <Typography 
              variant="h6"
              component="div" 
              fontWeight="bold" 
              noWrap
            >
              {truncatedName || 'Unnamed Store'}
            </Typography>
          </Tooltip>
          <Box display="flex" alignItems="center" gap={0.5}>
            <StarIcon sx={{ 
              color: displayStars ? 'primary.main' : 'text.secondary',
              fontSize: 18
            }} />
            <Typography variant="body2" color="text.secondary">
              {displayStars || ""}
            </Typography>
          </Box>
        </Box>

        {location && (
          <Box display="flex" alignItems="center">
            <LocationOnIcon sx={{ color: 'text.secondary', fontSize: 18, marginRight: 0.5 }} />
            <Typography variant="body2" color="text.secondary" noWrap>{location}</Typography>
          </Box>
        )}

        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ 
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            lineHeight: '1.4em',
            height: '2.8em',
            flex: '1 0 auto'
          }}
        >
          {truncatedDescription || 'No description available'}
        </Typography>

        <Box 
          sx={{ 
            display: 'flex',
            flexWrap: 'wrap',
            gap: 0.3,
            minHeight: '36px',
            alignItems: 'flex-start',
            marginTop: 'auto'
          }}
        >
          {safeCategories.map((category, index) => (
            <Chip 
              key={index} 
              label={category} 
              variant="outlined" 
              size="small" 
              sx={{ 
                height: '24px',
                '& .MuiChip-label': {
                  px: 0.75,
                  fontSize: '0.65rem',
                  lineHeight: '14px',
                  fontWeight: 400
                },
                '& .MuiChip-root': {
                  borderRadius: '12px'
                }
              }} 
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default WebsiteCard;
