import React, { useState } from 'react';
import { Box, Chip, Typography, Button } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Define default visible categories
const defaultVisibleCategories = [
    '70s', '80s', '90s', 'Y2K', 'Streetwear', 'Designer', 'Bohemian', 'Casual',
    'Women\'s', 'Men\'s', 'Shoes', 'Handmade', 'Formal'
];

const categoryGroups = [
    // {
    //     title: 'Eras',
    //     items: ['70s', '80s', '90s', 'Y2K']
    // },
    {
        title: 'Style',
        items: ['Athleisure', 'Avant Garde', 'Bohemian', 'Business Casual', 'Casual', 'Collegiate', 
                'Contemporary', 'Formal', 'Minimalist', 'Preppy', 'Streetwear', 'Vintage']
    },
    {
        title: 'Aesthetic',
        items: ['Bikercore', 'Coquette Girl', 'Girlhoodcore', 'Gorpcore', 'Goth', 'Grunge', 
                'Maximalism', 'Mobwife', 'Punk', 'Tennis Prep', 'Utility','Whimsigoth']
    },
    {
        title: 'Categories',
        items: ['Women\'s', 'Men\'s', 'Children\'s', 'Designer', 'Thrift', 'Shoes', 'DIY', 
                'Handmade', 'Upcycled']
    }
];

const CategoryFilter = ({ selectedCategories, onCategorySelect }) => {
    const [showAll, setShowAll] = useState(false);

    const categoryEmojis = {
        // Eras
        '70s': '🕺',
        '80s': '📼',
        '90s': '💿',
        'Y2K': '👾',
        
        // Styles
        'Athleisure': '🏃‍♀️',
        'Avant Garde': '🎭',
        'Bohemian': '🌸',
        'Business Casual': '💼',
        'Casual': '👕',
        'Collegiate': '🎓',
        'Contemporary': '✨',
        'Formal': '👔',
        'Minimalist': '⚪',
        'Preppy': '🎽',
        'Streetwear': '🛹',
        'Vintage': '📷',
        
        // Aesthetic
        'Bikercore': '🏍️',
        'Coquette Girl': '🎀',
        'Girlhoodcore': '🌟',
        'Gorpcore': '🏔️',
        'Goth': '🦇',
        'Grunge': '🎸',
        'Maximalism': '💫',
        'Mobwife': '💅',
        'Punk': '⛓️',
        'Tennis Prep': '🎾',
        'Utility': '🔧',
        'Whimsigoth': '🌙',
        
        // Categories
        'Women\'s': '👚',
        'Men\'s': '👔',
        'Children\'s': '🧸',
        'Designer': '✨',
        'Thrift': '🏷️',
        'Shoes': '👟',
        'DIY': '🛠️',
        'Handmade': '🧵',
        'Upcycled': '♻️'
    };

    const renderChip = (category) => (
        <Chip
            key={category}
            label={
                <span>
                    {categoryEmojis[category]} {category}
                </span>
            }
            onClick={() => {
                if (selectedCategories.includes(category)) {
                    onCategorySelect(selectedCategories.filter(c => c !== category));
                } else {
                    onCategorySelect([...selectedCategories, category]);
                }
            }}
            variant={selectedCategories.includes(category) ? "filled" : "outlined"}
            sx={{
                borderRadius: '20px',
                height: '32px',
                backgroundColor: selectedCategories.includes(category) ? 
                    'rgba(0, 0, 0, 0.08)' : 'transparent',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                color: selectedCategories.includes(category) ? 
                    'text.primary' : 'text.secondary',
                '&:hover': {
                    backgroundColor: selectedCategories.includes(category) ? 
                        'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.04)',
                },
                transition: 'all 0.2s ease',
                fontWeight: selectedCategories.includes(category) ? 500 : 400,
            }}
        />
    );

    // Render default visible categories
    const renderDefaultCategories = () => {
        const midPoint = Math.ceil(defaultVisibleCategories.length / 2);
        const firstRow = defaultVisibleCategories.slice(0, midPoint);
        const secondRow = defaultVisibleCategories.slice(midPoint);

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 1.5 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                    {firstRow.map(category => renderChip(category))}
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                    {secondRow.map(category => renderChip(category))}
                </Box>
            </Box>
        );
    };

    // Render all other categories grouped
    const renderAllCategories = () => (
        <Collapse in={showAll}>
            {categoryGroups.map((group) => (
                <Box key={group.title} sx={{ mb: 2 }}>
                    <Typography 
                        variant="subtitle2" 
                        sx={{ 
                            mb: 1, 
                            color: 'text.secondary',
                            fontWeight: 500,
                            textTransform: 'uppercase',
                            fontSize: '0.75rem',
                            letterSpacing: '0.1em'
                        }}
                    >
                        {group.title}
                    </Typography>
                    <Box sx={{ 
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.5,
                    }}>
                        {group.items
                            .filter(category => !defaultVisibleCategories.includes(category))
                            .map(category => renderChip(category))}
                    </Box>
                </Box>
            ))}
        </Collapse>
    );

    return (
        <Box sx={{ width: '100%' }}>
            {renderDefaultCategories()}
            
            <Button
                onClick={() => setShowAll(!showAll)}
                startIcon={showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                sx={{ 
                    mb: showAll ? 1 : 0,
                    color: 'text.secondary',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                }}
            >
                {showAll ? 'Show Less' : 'More Filters'}
            </Button>
            
            {renderAllCategories()}
        </Box>
    );
};

export default CategoryFilter;
